<template>
  <div class="contact">
    <div class="banner">
      <el-carousel trigger="click" :interval="5000">
        <el-carousel-item>
          <img :src="require('@/assets/img/banner-2.png')" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="contact_box">
      <div class="container">
        <div class="contact_title">
          <h3>联系我们</h3>
        </div>
        <div class="contact_text">
          <span>邮箱：</span>beijingtiananmennb@163.com
        </div>
        <div class="contact_text">
          <span>微信号：</span>lzxin6700 
        </div>
        <div class="contact_text">
          <span>联系电话：</span>+8618611396643 
        </div>
        <div class="contact_text">
          <span>地址：</span>
          <!-- 海南省海口市龙华区滨海街道32号新外滩复兴城第3层C3001房 -->
          海南省澄迈县老城镇高新技术产业示范区海南生态软件园孵化楼四楼1001室
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.banner {
  .el-carousel__item {
    ::v-deep img {
      width: 100%;
      height: 647px;
      background-position: center 100%;
      object-fit: cover;
    }
  }
  ::v-deep .el-carousel__container {
    height: 647px;
  }
}
.contact_box {
  padding-top: 50px;
  padding-bottom: 100px;
  width: 100%;
  .contact_title {
    text-align: center;
    h3 {
      font-size: 42px;
      color: #000;
    }
  }
  .contact_text {
    margin-top: 30px;
    text-align: center;
    font-size: 24px;
    color: #000;
    span {
      font-weight: bold;
    }
  }
}
</style>
